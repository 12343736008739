<template>
  <div>
    <VueApexCharts
      type="area"
      :options="chartOptions"
      :series="series"
      height="350"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: { type: Array, default: [] },
  },
  data() {
    return {
      chartOptions: this.getChartOptions(),
    };
  },
  computed: {
    translatedCategories() {
      return [
        this.$t("Monday"),
        this.$t("Tuesday"),
        this.$t("Wednesday"),
        this.$t("Thursday"),
        this.$t("Friday"),
        this.$t("Saturday"),
        this.$t("Sunday"),
      ];
    },
  },
  watch: {
    translatedCategories() {
      this.updateChartOptions();
    },
  },
  methods: {
    getChartOptions() {
      return {
        chart: {
          type: "area",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: this.translatedCategories,
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
          y: {
            formatter: (value) => {
              return `${value}`;
            },
          },
        },
      };
    },
    updateChartOptions() {
      this.chartOptions = this.getChartOptions();
    },
  },
  mounted() {
    this.updateChartOptions();
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
